import { render, staticRenderFns } from "./DashboardLayout.vue?vue&type=template&id=0db0f7d0&scoped=true"
import script from "./DashboardLayout.vue?vue&type=script&lang=js"
export * from "./DashboardLayout.vue?vue&type=script&lang=js"
import style0 from "./DashboardLayout.vue?vue&type=style&index=0&id=0db0f7d0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0db0f7d0",
  null
  
)

export default component.exports