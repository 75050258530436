import store from "../users/store";

const moduleRoute = {
  path: "/reports",
  component: () =>
    import(
      /* webpackChunkName: "reports-module", webpackPrefetch: true */ "./Module.vue"
    ),
  children: [
    {
      path: "/",
      component: () =>
        import(
          /* webpackChunkName: "reports-home", webpackPrefetch: true */ "./views/Home.vue"
        ),
    },

    {
      path: "/profitAndLoss",
      component: () =>
        import(
          /* webpackChunkName: "profitAndLoss", webpackPrefetch: true */ "./views/ProfitAndLoss.vue"
        ),
    },

    {
      path: "/journalEntry",
      name: "journalEntry",
      component: () =>
        import(
          /* webpackChunkName: "journalEntry", webpackPrefetch: true */ "./views/JournalEntry/index.vue"
        ),
    },

    {
      path: "/generalLedger",
      component: () =>
        import(
          /* webpackChunkName: "generalLedger", webpackPrefetch: true */ "./views/GeneralLedger.vue"
        ),
    },

    {
      path: "/trialBalance",
      component: () =>
        import(
          /* webpackChunkName: "trialBalance", webpackPrefetch: true */ "./views/TrialBalance.vue"
        ),
    },
    //DetailedTrialBalance
{
  path: "/detailedTrialBalance",
  component: () =>
    import(
      /* webpackChunkName: "detailedTrialBalance", webpackPrefetch: true */ "./views/DetailedTrialBalance.vue"
    ),

},
    {
      path: "/balanceSheet",
      component: () =>
        import(
          /* webpackChunkName: "balanceSheet", webpackPrefetch: true */ "./views/BalanceSheet.vue"
        ),
    },

    // {
    //   path: "/billsReport",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "billsReport", webpackPrefetch: true */ "./views/BillsReport.vue"
    //     ),
    // },
    {
      path: "/VATReport",
      component: () =>
        import(
          /* webpackChunkName: "billsReport", webpackPrefetch: true */ "./views/VATReport.vue"
        ),
    },
    {
      path: "/VATExpenseReport",
      component: () =>
        import(
          /* webpackChunkName: "billsReport", webpackPrefetch: true */ "./views/VATExpenseReport.vue"
        ),
    },
    {
      path: "/VendorReport",
      component: () =>
        import(
          /* webpackChunkName: "billsReport", webpackPrefetch: true */ "./views/Vendor.vue"
        ),
    },
    {
      path: "/CustomerReport",
      component: () =>
        import(
          /* webpackChunkName: "billsReport", webpackPrefetch: true */ "./views/Customer.vue"
        ),
    },
   ],
  beforeEnter: (to, from, next) => {
    if (store.state.token) {
      localStorage.setItem("layout", "dashboard-layout");
      next();
      return;
    }
    localStorage.setItem("layout", "landing-layout");
    next("/login");
  },
};

export default (router) => {
  router.addRoute(moduleRoute);
};
